import { Box, CSSObject, Container } from "@mui/material";
import BazaarImage from "~/components/BazaarImage";
import { FC } from "react";
import { Link } from "@remix-run/react";
import Carousel from "~/components/carousel/Carousel";
import "@leenguyen/react-flip-clock-countdown/dist/index.css?__remix_sideEffect__";
import useWindowSize from "~/hooks/useWindowSize";

type Props = {banner: any[];};

const ThinBanner: FC<Props> = ({ banner }: Props) => {
  const carouselStyles: CSSObject = {
    overflow: "hidden",
    borderRadius: "3px",
    "& .carousel__dot-group": {
      mt: 0,
      left: 0,
      right: 0,
      bottom: 10,
      position: "absolute",
      "& div": {
        borderColor: "dark.main",
        "::after": { backgroundColor: "dark.main" }
      }
    }
  };
  const width = useWindowSize();
  return (
    <Container sx={{ mb: { xs: 2, md: 4 } }}>
      {/* <FlexBox> */}
      <Carousel
        spacing="0px"
        totalSlides={banner?.length}
        infinite={true}
        interval={6000}
        showDots={banner?.length > 1}
        dotColor={"#FFFFFF"}
        autoPlay={true}
        visibleSlides={1}
        showArrow={false}
        sx={carouselStyles}>

        {/* <Box sx={{ position: "relative" }}>
           <Box
             sx={{
               position: "absolute",
               left: "50%",
               transform: "translate(-50%, 0)",
               top: { xs: "15%", md: "20%" },
             }}
           >
             {width > 800 ? (
               <FlipClockCountdown
                 to={new Date("2024-01-01 00:00:00")}
                 labels={["ӨДӨР", "ЦАГ", "МИН", "СЕК"]}
                 labelStyle={{
                   fontSize: 10,
                   fontWeight: 500,
                   textTransform: "uppercase",
                 }}
                 digitBlockStyle={{
                   width: 40,
                   height: 60,
                   fontSize: 30,
                   // backgroundColor: "rgba(45, 104, 196)",
                   backgroundColor: "#3a84c9",
                 }}
                 dividerStyle={{ color: "#2f6ea8", height: 0.5 }}
                 separatorStyle={{ color: "white", size: "6px" }}
                 duration={0.5}
               ></FlipClockCountdown>
             ) : (
               <FlipClockCountdown
                 to={new Date("2024-01-01 00:00:00")}
                 labels={["", "", "", ""]}
                 labelStyle={{
                   fontSize: 10,
                   fontWeight: 500,
                   textTransform: "uppercase",
                 }}
                 digitBlockStyle={{
                   width: 20,
                   height: 30,
                   fontSize: 15,
                   backgroundColor: "#3a84c9",
                 }}
                 dividerStyle={{ color: "#2f6ea8", height: 0.5 }}
                 separatorStyle={{ color: "white", size: "3px" }}
                 duration={0.5}
               ></FlipClockCountdown>
             )}
           </Box>
           <Link to="t/31019">
             <img
               src={banner2 ? banner2[0].photoUrl : null}
               alt="New Year Animation"
               width={"100%"}
             />
           </Link>
          </Box> */}
        {(banner || []).map((banner, index) =>
        <Box
          key={index}
          sx={{
            display: "flex",
            overflow: "hidden",
            position: "relative",
            justifyContent: "flex-end",
            alignItems: "center"
          }}>

            <Link to={banner.link}>
              <BazaarImage
              src={banner.photoUrl}
              sx={{ width: "100%", borderRadius: "8px" }} />

            </Link>
          </Box>
        )}
      </Carousel>
      {/* </FlexBox> */}
    </Container>);

};

export default ThinBanner;